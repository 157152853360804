.hidden {
    display: none;
}
.vertical-padding {
    padding: 15px 0;
}

.purchase-title {
    color: #000;
    text-align: center;
    font-family: *Poppins, serif;
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: 42px;
    /* 150% */
}

.number-purchase {
    color: var(--accents-primary, #C8236F);
    text-align: center;
    font-family: *Poppins, serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    /* 100% */
    letter-spacing: 0.15px;
}

.number-purchase-subtitle {
    color: var(--m-3-sys-light-on-surface, #1D1B20);
    text-align: center;
    font-family: *Quicksand, serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    /* 125% */
    letter-spacing: 0.25px;
}


.purchase-filter {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border: 1px solid black;
    border-radius: 10px;
    padding: 6px 8px;
    margin: 0 15px;
    cursor: pointer;
}

.purchase-filter:hover {
    color: var(--accents-primary, #C8236F) !important;
    border: 1px solid #C8236F;
}

.purchase-filter:hover path {
    fill: #C8236F;
}

.active {
    background: var(--accents-primary-surface, #F8E1EC);
}

.svg-filter {
    display: flex;
    width: 16px;
    height: 16px;
    justify-content: center;
    align-items: center;
    gap: 10px;
}