.users-title {
    color: #000;
    text-align: center;
    font-family: *Poppins, serif;
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: 42px;
    /* 150% */
}


.add-user-button:hover path {
    fill: white;
}

.header-tab-users {
    color: #000;
    font-family: *Poppins, serif;
    font-size: 11px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    /* 218.182% */
    letter-spacing: 0.5px;
}

.link-tab-user a {
    color: var(--accents-primary, #C8236F);
    font-family: *Poppins, serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    /* 171.429% */
    letter-spacing: 0.5px;
    text-decoration-line: underline;
}

.content-tab-user {
    color: #000;
    font-family: *Poppins, serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    /* 171.429% */
    letter-spacing: 0.5px;
}

.svg-user {
    display: flex;
    width: 22px;
    height: 26px;
    padding: 6px 0px 2px 0px;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
}