.purchase-row {
    border: 1px solid black;
    border-radius: 15px;
    padding: 10px 24px;
    margin: 10px;
    text-align: left;
    display: flex;
    justify-content: center;
    align-items: center;
}

.currency-back {
    display: flex;
    width: 22px;
    height: 22px;
    padding: 3px;
    justify-content: center;
    align-items: center;
    border-radius: 80px;
    background: var(--accents-primary-surface, #F8E1EC);
}

.top-text-purchase {
    color: var(--m-3-sys-light-on-surface, #1D1B20);
    font-family: *Quicksand, serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    /* 150% */
    letter-spacing: 0.15px;
}

.bottom-text-purchase {
    color: var(--accents-primary, #C8236F);
    font-family: *Quicksand, serif;
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    /* 153.846% */
    letter-spacing: 0.25px;
}

.small-bottom-text-purchase {
    color: #8B8B8B;
    font-family: *Quicksand, serif;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    /* 153.846% */
    letter-spacing: 0.25px;
}

.price-purchase {
    color: var(--accents-primary, #C8236F);
    font-family: *Quicksand, serif;
    font-size: 21px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px;
    /* 104.762% */
    letter-spacing: 0.15px;
}