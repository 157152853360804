.primary-button {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 100px;
  border: 1px solid #A92B75;
  box-shadow: 0px 6px 25px 0px rgba(200, 35, 111, 0.60);
  text-align: center;
  font-family: *Poppins, serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  /* 142.857% */
  letter-spacing: 0.1px;
  text-transform: uppercase;
  padding: 8px;
  cursor: pointer;
  background-color: var(--accents-primary, #C8236F);
  color: white;
}

.primary-button path {
  fill: white;
}

.primary-button:hover {
  background-color: white;
  color: var(--accents-primary, #C8236F);
}

.primary-button:hover path {
  fill: var(--accents-primary, #C8236F);
}

.secondary-button {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 100px;
  border: 1px solid #A92B75;
  box-shadow: 0px 6px 25px 0px rgba(200, 35, 111, 0.60);
  text-align: center;
  font-family: *Poppins, serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  /* 142.857% */
  letter-spacing: 0.1px;
  text-transform: uppercase;
  padding: 8px;
  cursor: pointer;
  background-color: white;
  color: var(--accents-primary, #C8236F);
}

.secondary-button path {
  fill: var(--accents-primary, #C8236F);
}

.secondary-button:hover {
  background-color: var(--accents-primary, #C8236F);
  color: white;
}

.secondary-button:hover path {
  fill: white;
}

.clickable-icon {
  cursor: pointer;
}

.clickable-icon:hover path {
  fill: var(--accents-primary, #C8236F);
}

.dashboard {
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
}

.dashboard__container {
  display: flex;
  flex-direction: column;
  text-align: center;
  background-color: #dcdcdc;
  padding: 30px;
  height: fit-content;
}

.dashboard__btn {
  margin-top: 10px;
}

.dashboard div {
  margin-top: 7px;
}

.dash-container {
  padding-top: 36px;
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  gap: 36px;
  width: 100%;
}

.dash-title {
  color: #000;
  text-align: center;
  font-family: *Poppins, serif;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 42px;
  /* 150% */
}

.container-numeri {
  display: flex;
  align-items: flex-start;
  gap: 16px;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  justify-content: space-around;
}

@media only screen and (max-width: 600px) {
  .container-numeri {
    flex-direction: column;
  }
}

.box-numeri {
  display: flex;
  justify-content: center;
  flex-basis: 100%;
  width: 100%;
  height: 100%;
  padding: 24px;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  border-radius: 20px;
  background: #FFF;
  box-shadow: 0px 1px 24px 0px rgba(78, 33, 54, 0.25);
}

.numero {
  color: var(--accents-primary, #C8236F);
  text-align: center;
  font-family: *Poppins, serif;
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  /* 109.091% */
  letter-spacing: 0.15px;
}

.subtitle-numero {
  color: var(--m-3-sys-light-on-surface, #1D1B20);
  text-align: center;
  font-family: *Quicksand, serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  /* 125% */
  letter-spacing: 0.25px;
}

.box-clubs {
  display: flex;
  padding: 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;
  border-radius: 20px;
  background: #FFF;
  box-shadow: 0px 1px 24px 0px rgba(78, 33, 54, 0.25);
}

.club-logo-container {
  display: flex;
  align-items: flex-start;
  gap: -10px;
}