.modal-label {
    color: #000;
    font-family: *Poppins, serif;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    margin-left: 10px;
    /* 123.077% */
}

.modal-field {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex-shrink: 0;
    align-self: stretch;
    border-radius: 4px 4px 0px 0px;
}

.modal-image-field {
    align-items: center;
}

.modal-input {
    color: #62707c;
    background: #fff;
    box-sizing: border-box;
    -webkit-appearance: none;
    padding: 5px 15px;
    border: solid 1px #d4dde5;
    transition: 0.1s ease-in-out;
    border-radius: 2px;
    font-family: *Roboto, serif;
    font-size: 16px;
    width: 100%;
    letter-spacing: 0.5px;
    border-radius: 20px !important;
}

.riga-input-modal {
    padding-bottom: 20px;
}