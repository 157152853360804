.logo-img {
    display: flex;
    width: 91.716px;
    height: 33.492px;
    justify-content: center;
    align-items: center;
    gap: 6.923px;
    flex-shrink: 0;
}

.header-link {
    display: flex;
    align-items: center;
    gap: 12px;
    padding: 0 30px;

    color: var(--m-3-sys-light-on-surface, #1D1B20);
    font-family: *Poppins, serif;
    ;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    /* 150% */
    letter-spacing: 0.15px;
    text-decoration: none;
}

.header-link:hover {
    color: var(--accents-primary, #C8236F);
    text-decoration: auto;
}
.header-link:hover path {
    fill: #C8236F;
}

.header-link svg {
    display: flex;
    width: 21px;
    height: 21px;
    justify-content: center;
    align-items: center;
    fill: black;
}


.logout {
    color: var(--accents-primary, #C8236F);
    text-align: center;
    font-family: *Quicksand, serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
    /* 18px */
    letter-spacing: -0.132px;
    cursor: pointer;
}
.logout :hover {
    text-decoration: underline !important;
}



.header_btn {
    margin-left: 15px;
    width: auto !important;
}

.menu_btn {
    background-color: #521559;
    border-radius: 25px;
}

hr {
    margin-top: 1rem;
    margin-bottom: 1rem;
    border: 0;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
}