.clubInfoImg {
    padding: 0 15px !important;
    margin: 0 !important;
    width: 400px;
    height: 150px;
    object-fit: contain;
}

.club-box-selected {
    border-radius: 20px;
    border: 2px solid var(--accents-primary, #C8236F);
    background: #FFF;
    box-shadow: 0px 1px 24px 0px rgba(78, 33, 54, 0.25);
}

.club-box:hover {
    cursor: pointer;
    background-color: rgba(210, 210, 210, 0.517);
    border: 2px solid var(--accents-primary, #C8236F);

}

.promotion-row {
    border: 1px solid black;
    border-radius: 15px;
    padding: 10px 24px;
    margin: 10px;
    text-align: left;
    display: flex;
    justify-content: center;
    align-items: center;
}

.top-text-promotion {
    color: var(--m-3-sys-light-on-surface, #1D1B20);
    font-family: *Quicksand, serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    /* 150% */
    letter-spacing: 0.15px;
}

.bottom-text-promotion {
    color: var(--m-3-sys-light-on-surface, #1D1B20);
    font-family: *Quicksand, serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    /* 166.667% */
    letter-spacing: 0.25px;
}