.user-info-column {
    display: flex;
    padding: 24px 12px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 24px;
    background: rgba(200, 35, 111, 0.03);
    text-align: left;
    align-items: flex-start;
}

.user-edit-button {
    color: var(--accents-primary, #C8236F);
    font-family: *Poppins, serif;
    font-size: 11px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    /* 218.182% */
    letter-spacing: 0.5px;
    cursor: pointer;
}

.user-data-title {
    color: #000;
    font-family: *Poppins, serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 42px;
    /* 233.333% */
}

.user-data-label {
    color: #000;
    font-family: *Quicksand, serif;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    /* 19.5px */
    letter-spacing: -0.143px;
    margin-bottom: 0px;
}

.user-data-text {
    color: #000;
    font-family: *Quicksand, serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
    /* 24px */
    letter-spacing: -0.176px;
}

.user-data-link {
    color: var(--accents-primary, #C8236F);
    font-family: *Poppins, serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    /* 171.429% */
    letter-spacing: 0.5px;
    text-decoration-line: underline;
    cursor: pointer;
}

.user-data-link:hover {
    text-decoration: none;
}

.user-id-field {
    color: #000;
    font-family: *Quicksand, serif;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    letter-spacing: -0.143px;
}

.divider {
    border: 1px solid #BDBDBD;
    width: 100%;
}

.drinx-table-label {
    color: #000;
    font-family: *Poppins, serif;
    font-size: 11px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    /* 218.182% */
    letter-spacing: 0.5px;
}

.drinx-table-club {
    color: var(--accents-primary, #C8236F);
    font-family: *Poppins, serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    /* 171.429% */
    letter-spacing: 0.5px;
    text-decoration-line: underline;
}

.drinx-table-points {
    overflow: hidden;
    color: #000;
    text-overflow: ellipsis;
    font-family: *Poppins, serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    /* 171.429% */
    letter-spacing: 0.5px;
}